<!--
 * @ Author: Lmi
 * @ Create Time: 2023-09-04 15:00:55
 * @ Modified by: Your name
 * @ Modified time: 2023-09-04 15:00:55
 * @ Description: 数据统计>平台统计>站点基础充电信息统计 
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="station-information-statistics">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { offlineExport, code, getYesdayMonth } from '@/utils';
  import { dataStatisticsServe, barrierPage } from '../../../../service/apis';

  export default {
    name: 'stationInformationStatisticsList',

    data() {
      return {
        pageButtons: [
          {
            id: '0',
            text: '导出',
            type: 'plain',
            enabled: () => code('station:charge:info:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '电站名称', prop: 'stationName', minWidth: '180px' },
          { label: '总次数', prop: 'totalNums', minWidth: '100px' },
          { label: '单枪平均充电次数', prop: 'avgChargingPerPile', minWidth: '120px' },
          { label: '平均每天充电次数', prop: 'avgChargingPerDay', minWidth: '120px' },
          { label: '单枪平均每天充电次数', prop: 'avgChargingPerPilePerDay', minWidth: '150px' },
          { label: '充电度数0—20度', prop: 'charging0to20', minWidth: '120px' },
          { label: '充电度数20—30度', prop: 'charging20to30', minWidth: '120px' },
          { label: '充电度数30—40度', prop: 'charging30to40', minWidth: '120px' },
          { label: '充电度数40度以上', prop: 'chargingAbove40', minWidth: '120px' },
          { label: '站点月份总度数', prop: 'totalPerMonth', minWidth: '120px' },
          { label: '单枪月份平均度数', prop: 'avgPerPilePerMonth', minWidth: '120px' },
          { label: '单枪每天平均度数', prop: 'avgPerPilePerDay', minWidth: '120px' },
          { label: '站点月份总收入', prop: 'totalIncomePerMonth', minWidth: '120px' },
          { label: '单枪月份平均收入', prop: 'avgIncomePerPilePerMonth', minWidth: '120px' },
          { label: '单枪每天平均收入', prop: 'avgIncomePerPilePerDay', minWidth: '120px' },
          { label: '充电总时长', prop: 'totalChargingDuration', minWidth: '100px' },
          { label: '使用率', prop: 'utilizationRate', minWidth: '100px' },
        ],
        tableTitle: '明细列表',
        pageInfo: {
          current: 1,
          size: 10,
        },
        stationData: [], // 电站数据
        searchParams: {
          queryDate: '',
          stationId: '',
        },
      };
    },
    created() {
      this.searchParams.queryDate = getYesdayMonth(); // 默认显示年月(获取当前日期前一天所在年-月)
      console.log('---this.searchParams.queryDate', this.searchParams.queryDate);
      this.getStationData();
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            label: '时间维度',
            key: 'queryDate',
            comName: 'YkcDatePicker',
            type: 'month',
            format: 'yyyy-MM',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationData,
            remote: true,
          },
        ];
      },
    },
    methods: {
      /**
       * @desc 获取电站数据
       * */
      getStationData() {
        barrierPage.findStationList({}).then(res => {
          this.stationData = (res || []).map(item => {
            return {
              id: item.stationId,
              name: item.stationName,
            };
          });
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        dataStatisticsServe
          .stationInfoStatisticsList({
            ...this.pageInfo,
            ...this.searchParams,
          })
          .then(res => {
            this.tableData = res.records;
            this.pageInfo.total = res.total || 0;
          });
      },
      /**
       * @desc 导出文件 - 站点基础充电信息列表
       * */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'base_charging_info_statistics',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        this.searchParams.queryDate = getYesdayMonth(); // 清空显示默认年月
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
